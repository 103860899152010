import { AppConfig } from "app/core";
import { commonEnvironment } from "./environment-common";

export const environment: AppConfig = {
  ...commonEnvironment,
  botName: "pop",
  backendBaseUrl: "https://ui-api.popbot-prod.probayes.net/v0/",
  headerLogoUrl: "/assets/specific/pop/launcher.png",
  headerLogoAnimations: {
    duration: 4000,
    play_half: true,
    default: {
      path: "/assets/specific/pop/default_animation.json",
      duration: 1500,
    },
  },
  reducedBotLogoUrl: "/assets/specific/pop/bot-icon-circled.svg",
  reducedBotHasWelcomeText: true,
  hasEvaluationWithStars: true,
  shouldAskToKeepConversation: true,
  convertEmojisToImages: true,
  messageAvatarUrl: "/assets/specific/pop/bot-icon.svg",
  hostDimensions: {
    openHeight: 580,
    openWidth: 340,
    closedHeight: 150,
    closedWidth: 200,
    closedMobileHeight: 100,
    closedMobileWidth: 130,
  },
  delayInConversationMessagesDisplayInMs: 1500,
};
